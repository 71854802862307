import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../../components/layout"
import isEqual from "lodash.isequal"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/add-to-cart"
import { NumericInput } from "../../../components/numeric-input"
import { formatPrice } from "../../../utils/format-price"
import { Seo } from "../../../components/seo"

import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import {
  productBox,
  container,
  header,
  productImageWrapper,
  productDetails,
  productName,
  selection,
  selectionItem,
  scrollForMore,
  noImagePreview,
  optionsWrapper,
  priceValue,
  tagList,
  tagItem,
  addToCartStyle,
  productDescription,
  carouselDotGroup
} from "./product-page.module.css"

export default function Product({ data: { product, suggestions } }) {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    descriptionHtml,
    images,
    images: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1

  return (
    <Layout>
      {firstImage ? (
        <Seo
          title={title}
          description={description}
          image={getSrc(firstImage.gatsbyImageData)}
        />
      ) : undefined}
      <div className={container}>
        <div className={productBox}>
          {hasImages && (
            <div className={productImageWrapper}>
              <div
                role="group"
                aria-label="gallery"
                aria-describedby="instructions"
              >
                <CarouselProvider
                  className="ProductPage__carousel"
                  naturalSlideWidth={100}
                  naturalSlideHeight={100}
                  totalSlides={product.images.length}
                  disableAnimation={true}
                  dragEnabled={false}
                >
                    <Slider className="ProductPage__slider">
                      {images.map((image, index) => (
                        <Slide index={index} key={index+'slide'}>
                          <GatsbyImage
                            objectFit="contain"
                            loading={index === 0 ? "eager" : "lazy"}
                            key={`product-image-${image.id}`}
                            alt={
                              image.altText
                                ? image.altText
                                : `Product Image of ${title} #${index + 1}`
                            }
                            image={image.gatsbyImageData}
                          />
                        </Slide>
                      ))}
                  </Slider>
                  <DotGroup dotNumbers className={carouselDotGroup}/>
                </CarouselProvider>
              </div>
              {hasMultipleImages && (
                <div className={scrollForMore} id="instructions">
                  <span aria-hidden="true">←</span> scroll for more{" "}
                  <span aria-hidden="true">→</span>
                </div>
              )}
            </div>
          )}
          {!hasImages && (
            <span className={noImagePreview}>No Preview image</span>
          )}
          <div className={productDetails}>
            <div className={header}>
              <div className={tagList}>
                {product.tags.map((tag, index) => (
                  <span key={`${index}-tag`} className={tagItem}>{tag}</span>
                ))}
              </div>
              <h1 className={productName}>{title}</h1>
            </div>
            <h2 className={priceValue}>
              <span>Price: {price}</span>
            </h2>
            <fieldset className={optionsWrapper}>
              {hasVariants &&
                options.map(({ id, name, values }, index) => (
                  <div key={`${id}-container`}>
                    <label key={`${id}-label`} htmlFor={name}>Select {name}:</label>
                    <div
                      className={selection}
                      key={id}
                      onChange={event => handleOptionChange(index, event)}
                    >
                      {values.map((value, index) => (
                        <div className={selectionItem} key={`${name}-${value}`}>
                          <input
                            name={name}
                            value={value}
                            id={`${name}-${value}`}
                            // disabled={checkDisabled(name, value)}
                            type="radio"
                          />
                          <label htmlFor={`${name}-${value}`}>{value}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </fieldset>
            <div className={addToCartStyle}>
              <NumericInput
                  aria-label="Quantity"
                  onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                  onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                  onChange={(event) => {
                    setQuantity(event.currentTarget.value)
                  }}
                  value={quantity}
                  min="1"
                  max="20"
                />
              <AddToCart
                variantId={productVariant.storefrontId}
                quantity={quantity}
                available={available}
              />
            </div>
            <div className={productDescription} dangerouslySetInnerHTML={{__html: descriptionHtml}} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      descriptionHtml
      productType
      productTypeSlug: gatsbyPath(
        filePath: "/products/{ShopifyProduct.productType}"
      )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        # altText
        id
        gatsbyImageData(layout: CONSTRAINED, width: 640)
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`
